<template>
  <div>
    <div class="d-flex flex-column">
      <!-- <ThePageTitle>Give consent</ThePageTitle> -->

      <div class="payment-method-title mt-6 mb-3">
        <p
          tabindex="0"
          aria-label="Payment to"
          class="my-0 text-center text-uppercase"
        >
          payment to
        </p>
      </div>

      <v-card outlined class="grey lighten-3 rounded-md">
        <v-card-text>
          <div v-if="!$store.state.paymentDetails.IsGenericLink">
            <div class="d-flex justify-space-between">
              <div>
                <p tabindex="0" class="text-caption my-0 font-weight-light">
                  Account name
                </p>
                <p tabindex="0" class="text-caption mt-n1 mb-1">
                  {{ $store.state.paymentDetails.BankAccountName }}
                </p>
              </div>
              <div class="mb-3">
                <p
                  tabindex="0"
                  class="text-caption text-right my-0 font-weight-light"
                >
                  Payment reference
                </p>
                <p tabindex="0" class="text-caption mt-n1 text-right">
                  {{ $store.state.requestID }}
                </p>
              </div>
            </div>
            <div
              v-for="payment in $store.state.paymentDetailsToSubmit.breakDown"
              :key="payment.Id"
              class="mb-2 d-flex justify-space-between text-body-1"
            >
              <p class="my-0">{{ payment.Description }}</p>
              <p class="my-0">{{ payment.userInputAmount.toFixed(2) }}</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <div tabindex="0" class="d-flex justify-space-between text-body-1">
              <p class="my-0 font-weight-black">Total amount</p>
              <p class="my-0 font-weight-black">
                GBP {{ $store.state.paymentDetailsToSubmit.total }}
              </p>
            </div>
          </div>

          <div v-if="$store.state.paymentDetails.IsGenericLink">
            <div class="d-flex justify-space-between">
              <div>
                <p tabindex="0" class="text-caption my-0 font-weight-light">
                  Account name
                </p>
                <p tabindex="0" class="text-caption mt-n1 mb-1">
                  {{ $store.state.paymentDetails.BankAccountName }}
                </p>
              </div>
              <div class="mb-3">
                <p
                  tabindex="0"
                  class="text-caption text-right my-0 font-weight-light"
                >
                  Payment reference
                </p>
                <p tabindex="0" class="text-caption mt-n1 text-right">
                  {{ $store.state.requestID }}
                </p>
              </div>
            </div>
            <v-divider class="my-3"></v-divider>
            <div tabindex="0" class="d-flex justify-space-between text-body-1">
              <p class="my-0 font-weight-black">Total amount</p>
              <p class="my-0 font-weight-black">
                {{ formatGenericLinkAmount }}
              </p>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <div class="payment-method-title mt-6 mb-3">
        <p tabindex="0" class="my-0 text-center text-uppercase">payment from</p>
      </div>

      <SelectedBankAndLogo />

      <p tabindex="0" class="text-center text-body-1 text-sm-h6 mt-12">
        You will be securely redirected to your bank to authenticate this
        payment.
      </p>

      <v-btn
        @click="confirmContinue"
        :loading="loading"
        class="mb-3"
        x-large
        depressed
        color="primary"
        block
      >
        confirm & continue
      </v-btn>

      <ModulrTermsAndConditions />
    </div>
  </div>
</template>

<script>
import ModulrTermsAndConditions from "@/components/ModulrTermsAndConditions.vue";
import SelectedBankAndLogo from "@/components/SelectedBankAndLogo.vue";
import { API } from "aws-amplify";

export default {
  name: "PaymentConfirmation",
  components: {
    ModulrTermsAndConditions,
    SelectedBankAndLogo,
  },
  data() {
    return {
      overlayLoader: false,
      loading: false,
    };
  },
  methods: {
    reRoute() {
      this.$router.push({ name: "PaymentSuccess" });
    },
    confirmContinue() {
      this.loading = true;
      if (this.$store.state.paymentDetails.IsGenericLink)
        this.createGenericLinkParams();
      else {
        this.createPaymentParams();
      }
    },
    createPaymentParams() {
      // create payments array from submitted values in $store
      const fullPaymentDetails =
        this.$store.state.paymentDetailsToSubmit.breakDown;
      const submissionPaymentDetails = fullPaymentDetails.map((item) => {
        return {
          id: item.ID,
          amount: item.userInputAmount,
        };
      });
      this.postPayment(submissionPaymentDetails);
    },
    createGenericLinkParams() {
      const submissionPaymentDetails = [
        {
          id: this.$store.state.paymentDetailsToSubmit.breakDown[0]
            .UserInputtedReference,
          amount: this.$store.state.paymentDetailsToSubmit.breakDown[0].Amount,
        },
      ];
      this.postPayment(submissionPaymentDetails);
    },
    postPayment(paymentDetails) {
      // Submission model
      const postParams = {
        body: {
          payload: { payments: paymentDetails },
          modulrBankID: this.$store.state.selectedBank.ModulrID,
          requestID: this.$store.state.requestID,
          payMethod: "Transfer", // hard coded for Modulr bank transfers
          initialPayment: "", // not needed on oneOff payment
          finalPayment: "", // not needed on oneOff payment
        },
      };

      API.post("paymentAppRest", "/modulr", postParams).then((res) => {
        this.loading = false;
        if (res.status === "200") {
          if (res.modulrURL.modulrID) {
            this.$store.commit("SET_MODULR_ID", res.modulrURL.modulrID); // API request returns ModulrID and URL for reroute
            if (this.$store.state.safariBrowser) {
              // if Safari browser - set a number of session cookies to 5 mins
              this.createCookie("modulrID", res.modulrURL.modulrID, 5);
              this.createCookie(
                "payAgentRequestID",
                this.$store.state.requestID,
                5
              );
              this.createCookie(
                "payAgentPaymentDetails",
                JSON.stringify(this.$store.state.paymentDetails),
                5
              );
              this.createCookie(
                "payAgentPaymentDetailsSubmitted",
                JSON.stringify(this.$store.state.paymentDetailsToSubmit),
                5
              );
            }
            // window.open(res.modulrURL.url, "_self")
            window.location.href = res.modulrURL.url;
          }
          if (res.modulrURL.error) alert(res.modulrURL.error);
        } else {
          console.log("Error: ", res);
          this.loading = false;
        }
      });
    },
    createCookie(name, value, minutes) {
      let expires;
      if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + minutes * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }
      document.cookie = name + "=" + value + expires;
    },
  },
  computed: {
    formatGenericLinkAmount() {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(this.$store.state.paymentDetailsToSubmit.total);
    },
  },
};
</script>
